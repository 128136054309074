<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="work-index-wrap"
  >
    <div class="work-warp">
      <h2>欢迎回来，{{user.userName}}</h2>
      <div class="w-level"> <span class="icon-level"></span> {{user.company}} / {{user.roleName || '总账号'}} </div>
    </div>
    <div class="w-content-wrap">
      <div class="w-content-left fl">
        <span class="w-app-title-name">应用中心</span>
        <div class="w-app-list-wrap">
          <ul>
            <li
              v-for="item in modulesList"
              :key="item.id"
              @click="toModules(item)"
            >
              <div class="w-app-wraps">
                <div class="w-app-img">
                  <img
                    :src="item.img"
                    alt=""
                  >
                </div>
                <div
                  class="w-app-title"
                  :class="[item.id === 188 ? 'grey-color1' : '']"
                >{{item.cnname}}</div>
                <div
                  class="w-app-text"
                  :class="[item.id === 188 ? 'grey-color2' : '']"
                >{{item.remark}}</div>
              </div>
            </li>
          </ul>
        </div>
        <div class="clearfix"></div>
        <!-- <i class="w-shadow"></i> -->
      </div>
      <div class="w-content-right fl">
        <div class="w-right-top">
          <el-tabs v-model="activeName">
            <el-tab-pane
              label="待办事项提醒"
              name="first"
            >
              <span slot="label">待办事项提醒
                <i
                  v-if="sysMessageNumber > 0"
                  class="w-msg-label"
                >
                  <span class="left-triangle"></span>{{sysMessageNumber > 10 ? '10+' : sysMessageNumber}}</i>
              </span>
              <div
                class="w-table-content"
                v-if="messagelist && messagelist.length > 0"
              >
                <div
                  v-for="item in messagelist"
                  :key="item.id"
                  class="w-table-item"
                >
                  <span class="w-table-title ellipsis">{{item.title}}</span>
                  <span class="w-table-time ellipsis">{{item.date}}</span>
                  <!-- 按后端的意思isShow、isDelete是没有意义的出参，现在用isEnble判断是否显示查看详情按钮 -->
                  <span
                    v-if="item.isEnble === 0"
                    class="w-table-btn ellipsis"
                    @click="toMessage(item)"
                  >查看详情</span>
                </div>
              </div>
              <div
                class="w-table-content"
                v-if="messagelist && messagelist.length === 0"
              >
                <div class="no-recommend-brand">
                  <img
                    style="margin-top: 40px;"
                    src="@/assets/images/public/not-plandata.png"
                    alt=""
                  >
                  <p>暂无相关数据</p>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane
              label="品牌更新提醒"
              name="second"
            >
              <span slot="label">品牌更新提醒
                <i
                  v-if="syscBrandNumber > 0"
                  class="w-msg-label"
                  style="right: 24px;"
                ><span class="left-triangle"></span>{{syscBrandNumber > 10 ? '10+' : syscBrandNumber}}</i>
              </span>
              <div
                class="w-table-content"
                v-if="sysList && sysList.length > 0"
              >
                <div
                  class="w-table-item"
                  v-for="item in sysList"
                  :key="item.id"
                >
                  <span class="w-table-title ellipsis">{{item.mtext}}</span>
                  <span class="w-table-time ellipsis">{{item.addTimeString}}</span>
                  <span
                    class="w-table-btn ellipsis"
                    @click="toDetail(item)"
                  >具体更新品牌信息</span>
                </div>
              </div>
              <div
                class="w-table-content"
                v-if="sysList && sysList.length === 0"
              >
                <div class="no-recommend-brand">
                  <img
                    style="margin-top: 40px;"
                    src="@/assets/images/public/not-plandata.png"
                    alt=""
                  >
                  <p>暂无相关数据</p>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane
              label="资讯更新提醒"
              name="third"
            >
              <span slot="label">资讯更新提醒
                <i
                  v-if="syscNewsNumber > 0"
                  class="w-msg-label"
                  style="right: -38px;"
                >
                  <span class="left-triangle"></span>{{syscNewsNumber > 10 ? '10+' : syscNewsNumber}}</i>
              </span>
              <div class="w-table-content">
                <div
                  class="w-table-item"
                  v-for="item in newsList"
                  :key="item.newsId"
                >
                  <span class="w-table-title ellipsis">{{item.title}}</span>
                  <span class="w-table-time ellipsis">{{item.newsTime}}</span>
                  <span
                    class="w-table-btn ellipsis"
                    @click="toNews(item)"
                  >查看详情</span>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="w-right-center">
          <span class="w-content-function">常用功能</span>
          <span
            class="w-manage-btn cursor"
            @click="toCommonListFunc()"
          >管理</span>
          <div class="w-used-list">
            <div
              class="w-used-item"
              v-for="item in oftenList"
              :key="item.id"
              @click="toPage(item)"
            >
              <div class="w-is-has-used">
                <p class="w-used-title"><span :class="['icon-' + item.sign]"></span>{{item.cnname2}}</p>
                <p class="w-used-tips">{{item.remark}}</p>
              </div>
            </div>
            <template v-if="oftenList && oftenList.length !== 0">
              <div
                class="w-used-item"
                v-for="(item, index) in (6 - oftenList.length)"
                :key="index"
                @click="toCommonListFunc()"
              >
                <div class="w-is-not-used">
                  您可增加常用功能入口
                </div>
              </div>
            </template>
          </div>
          <div
            class="w-is-not-data"
            v-if="oftenList && oftenList.length === 0"
          >
            <img
              src="@/assets/images/public/not-plandata.png"
              alt=""
            >
            <p class="w-not-tips">您当前还未添加常用功能入口</p>
            <p
              class="w-add-used-btn cursor"
              @click="toCommonListFunc()"
            >添加常用功能</p>
          </div>
        </div>
        <div class="w-right-bottom">
          <span class="w-use-help">使用帮助</span>
          <router-link
            tag="a"
            :to="{ path: '/knowledgeBase/322'}"
            target="_blank"
            class="w-use-into"
          >进入{{!isGxjt ? '赢商' : ''}}知识库</router-link>
          <div class="use-help-wrap">
            <div class="help-wrap-left">
              <div class="title">操作指南</div>
              <router-link
                tag="a"
                :to="{ path: '/knowledgeBase/322'}"
                target="_blank"
                class="help-item"
              >
                <p class="txt">产品白皮书</p>
                <p><span class="icon-toback"></span></p>
              </router-link>
              <router-link
                tag="a"
                :to="{ path: '/knowledgeBase/319'}"
                target="_blank"
                class="help-item"
              >
                <p class="txt">操作功能列表</p>
                <p><span class="icon-toback"></span></p>
              </router-link>
              <router-link
                tag="a"
                :to="{ path: '/knowledgeBase/356'}"
                target="_blank"
                class="help-item"
              >
                <p class="txt">常见问题</p>
                <p><span class="icon-toback"></span></p>
              </router-link>
            </div>
            <div class="help-wrap-right">
              <div class="title">视频教学</div>
              <router-link
                tag="a"
                :to="{ path: '/knowledgeBase/421'}"
                target="_blank"
                class="help-item"
              >
                <p class="txt">看板数据关联</p>
                <p><span class="icon-video"></span></p>
              </router-link>
              <router-link
                tag="a"
                :to="{ path: '/knowledgeBase/425'}"
                target="_blank"
                class="help-item"
              >
                <p class="txt">看板制作</p>
                <p><span class="icon-video"></span></p>
              </router-link>
              <router-link
                tag="a"
                :to="{ path: '/knowledgeBase/427'}"
                target="_blank"
                class="help-item"
              >
                <p class="txt">看板预警监控</p>
                <p><span class="icon-video"></span></p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/api'
export default {
  data () {
    return {
      loading: true,
      activeName: 'first',
      oftenList: [],
      sysList: [],
      newsList: [],
      messagelist: [],
      syscBrandNumber: 0,
      syscNewsNumber: 0,
      sysMessageNumber: 0,
      modulesList: [],
      dbUrlList: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token'
    }),
    url () {
      let temUrl = ''
      if (process.env.NODE_ENV === 'development') {
        temUrl = 'http://' + document.domain + ':8080'
      } else if (window.location.href.indexOf('http://') > -1) {
        temUrl = 'http://' + document.domain
      } else {
        temUrl = 'https://' + document.domain
      }
      return temUrl
    }
  },
  mounted () {
    this.getSysMessagelist()
    this.getOftenConfigFunc()
    this.getSyncBrandListFunc()
    this.getRecommBrandListFunc()
    this.getModularListFunc()
    this.init()
  },
  methods: {
    isIE () { // ie?
      if (!!window.ActiveXObject || 'ActiveXObject' in window) { return true } else { return false }
    },
    handleClick () { },
    toPage (item) {
      localStorage.removeItem('saveScreen')
      if (item.sign === 'zsgh_xmzt') {
        window.location.href = this.url + '/#/work'
      } else if (item.sign === 'zsgh_zsmb') {
        window.location.href = this.url + '/#/investmentAimMange'
      } else if (item.sign === 'zsgh_pwzs') {
        window.location.href = this.url + '/#/berthInvestmentManage'
      } else if (item.sign === 'zsgh_pwch') {
        window.location.href = this.url + '/#/detachingShops'
      } else if (item.sign === 'zsss_zsgj') {
        window.location.href = this.url + '/#/implement'
      } else if (item.sign === 'zsss_spqy') {
        window.location.href = this.url + '/#/shopsSinged'
      } else if (item.sign === 'zsss_zsbg') {
        window.location.href = this.url + '/#/merchantReports'
      } else if (item.sign === 'zsgh_ghfx') {
        window.location.href = this.url + '/#/investmentPlanAnalysis?type=1'
      } else if (item.sign === 'zsss_ssfx') {
        window.location.href = this.url + '/#/implementationAnalysis?type=1'
      } else if (item.sign === 'zsss_jdgk') {
        window.location.href = this.url + '/#/implement'
      } else if (item.sign === 'zsss_ksgl') {
        window.location.href = this.url + '/#/visualization'
      } else if (item.sign === 'zsss_pwzs') {
        window.location.href = this.url + '/#/berthInvestmentManage'
      } else if (item.sign === 'zsss_ppzs') {
        window.location.href = this.url + '/#/investmentFollowManage'
      } else if (item.sign === 'zsss_zsjb') {
        window.location.href = this.url + '/#/merchantReports'
      } else {
        window.location.href = item.url + '&tokenval=' + this.token
      }
      this.ifIEdoSth()
    },
    ifIEdoSth () {
      if (document.domain.indexOf('local') !== -1 || document.domain.indexOf('prewbms') !== -1) {
        if (this.isIE()) {
          window.location.reload(true)
        }
      }
    },
    toModules (item) {
      if (item.id === 188) {
        // this.$message({
        //   type: 'warning',
        //   message: '持续升级中，敬请期待！'
        // })
      } else if (item.id === 7) {
        if (item.flag) {
          const res = this.user.confIds.filter(item => {
            return item.id === 209 || item.id === 210 || item.id === 211 || item.id === 212 || item.id === 213
          })
          if (res.length === 0) {
            this.$message({
              type: 'warning',
              message: '暂无权限访问此页！'
            })
            return false
          } else {
            const pathList = [
              'work',
              'leasingStrategy',
              'leasingPlan',
              'planningProject',
              'leasingAnalysis'
            ]
            res.sort((a, b) => {
              return a.id - b.id
            })
            window.location.href = this.url + '/#/' + pathList[res[0].id - 209]
            if (this.isIE() && res[0].id === 209) {
              this.ifIEdoSth()
            }
          }
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，集团暂未开通此应用模块！'
          })
        }
      } else if (item.id === 8) {
        if (item.flag) {
          const res8 = this.user.confIds.filter(item => {
            return item.id === 214 || item.id === 215 || item.id === 216 || item.id === 217 || item.id === 218
          })
          if (res8.length === 0) {
            this.$message({
              type: 'warning',
              message: '暂无权限访问此页！'
            })
            return false
          } else {
            const pathList8 = [
              'implement',
              'visualization',
              'berthInvestmentManage',
              'investmentFollowManage',
              'merchantReports'
            ]
            res8.sort((a, b) => {
              return a.id - b.id
            })
            window.location.href = this.url + '/#/' + pathList8[res8[0].id - 214]
            if (this.isIE() && res8[0].id === 214) {
              this.ifIEdoSth()
            }
          }
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，集团暂未开通此应用模块！'
          })
        }
      } else if (item.id === 128) {
        if (item.flag) {
          if (item.userFlag) {
            window.open(this.$shulieDomain + 'dashboard&token=' + this.token)
          } else {
            this.$message({
              type: 'warning',
              message: '抱歉，此账号暂无权限！'
            })
            return false
          }
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，集团暂未开通此应用模块！'
          })
          return false
        }
      } else if (item.id === 137) {
        if (item.flag) {
          if (item.userFlag) {
            window.open(this.$shulieDomain + 'storyboard&token=' + this.token)
          } else {
            this.$message({
              type: 'warning',
              message: '抱歉，此账号暂无权限！'
            })
            return false
          }
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，集团暂未开通此应用模块！'
          })
          return false
        }
      } else if (item.id === 231) {
        if (item.flag) {
          const res = this.user.confIds.filter(item => {
            return [
              231,
              232,
              233,
              234,
              235,
              236,
              237,
              238,
              239
            ].indexOf(item.id) !== -1
          })
          if (res.length === 0) {
            this.$message({
              type: 'warning',
              message: '暂无权限访问此页！'
            })
            return false
          } else {
            const pathList = [
              'workOrderCenter',
              'workSetting',
              'teamManagement'
            ]
            // res.sort((a, b) => {
            //   return a.id - b.id
            // })
            let key = 0
            const list = res.map(item => {
              return item.id
            })
            if (
              list.indexOf(232) !== -1 ||
              list.indexOf(235) !== -1 ||
              list.indexOf(236) !== -1 ||
              list.indexOf(237) !== -1 ||
              list.indexOf(238) !== -1 ||
              list.indexOf(239) !== -1
            ) {
              key = 0
            } else if (list.indexOf(233) !== -1) {
              key = 1
            } else if (list.indexOf(234) !== -1) {
              key = 2
            }
            window.location.href = this.url + '/#/' + pathList[key]
            if (this.isIE() && key === 0) {
              this.ifIEdoSth()
            }
          }
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，集团暂未开通此应用模块！'
          })
        }
      } else {
        if (item.flag) {
          if (item.userFlag) {
            window.location.href = this.$gaodeDomain + '?menuCode=' + item.id + '&tokenval=' + this.token
          } else {
            this.$message({
              type: 'warning',
              message: '抱歉，此账号暂无权限！'
            })
            return false
          }
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，集团暂未开通此应用模块！'
          })
          return false
        }
      }
    },
    init () {
      this.axios.post(api.getSyncBrandListNum)
        .then((res) => {
          this.syscBrandNumber = res.data.data
        })
      this.axios.post(api.getSyncNewsListNum)
        .then((res) => {
          this.syscNewsNumber = res.data.data
        })
      this.axios.post(api.newInformationNum)
        .then((res) => {
          this.sysMessageNumber = res.data.data
        })
      this.axios.post(api.getDPurl).then(res => {
        this.dbUrlList = res.data.data
      })
    },
    getModularListFunc () {
      this.axios.post(api.getModularList)
        .then((res) => {
          this.modulesList = res.data.data
        })
    },
    getOftenConfigFunc () {
      this.axios.post(api.getUserOftenConfigList)
        .then((res) => {
          this.oftenList = res.data.data
        })
    },
    getSyncBrandListFunc () {
      const data = {
        pageNum: 1,
        pageSize: 7
      }
      this.axios.post(api.getMessageList, data)
        .then((res) => {
          this.sysList = res.data.data.dataList
        })
    },
    getSysMessagelist () {
      const data = {
        date: [],
        endDate: '',
        startDate: '',
        pageNum: 1,
        pageSize: 10,
        state: '',
        total: 0,
        typeId: ''
      }
      this.axios.post(api.getEarlyWarningInformation, data)
        .then((res) => {
          this.messagelist = res.data.data.dataList
        })
    },
    getRecommBrandListFunc () {
      this.axios.post(api.getBrandNews, {
        pageNum: 1,
        pageSize: 5,
        category: '推荐',
        keyWord: ''
      })
        .then((res) => {
          this.newsList = res.data.data
          this.loading = false
        })
    },
    toDetail (item) {
      this.axios.post(api.updateUnreadNum, { type: 1 })
        .then((res) => { })
      const brandDetailsHref = this.$router.resolve({
        path: '/brandUpdateRescords'
      })
      window.open('/' + brandDetailsHref.href, '_blank')
    },
    toNews (item) {
      this.axios.post(api.updateUnreadNum, { type: 2 })
        .then((res) => { })
      // window.open(item.htmlUrl, '_blank')
      const brandTendencyDetail = this.$router.resolve({
        path: '/brandTendencyDetail',
        query: {
          title: encodeURIComponent('咨询更新'),
          newsId: item.newsId
        }
      })
      window.open('/' + brandTendencyDetail.href, '_blank')
    },
    toMessage (item) {
      this.axios.post(api.updateInformationMsgViewed, {
        ids: [item.id]
      })
        .then((res) => {
          this.axios.post(api.newInformationNum)
            .then((res) => {
              this.sysMessageNumber = res.data.data
            })
        })
      let id = 0
      if (item.typeId === 1) { // 1 招商任务提醒
        const brandTendencyDetail = this.$router.resolve({
          path: '/investmentFollowDetail',
          query: {
            id: item.taskId
          }
        })
        window.open('/' + brandTendencyDetail.href, '_blank')
      } else if (item.typeId === 2 || item.typeId === 3) { // 2 权益分配 3 回收提醒
        const routerRes = this.$router.resolve({ name: 'personalInfo', query: {} })
        window.open(routerRes.href, '_blank')
      } else if (item.typeId === 4) { // 4 联系特权提醒
        const routerRes = this.$router.resolve({ name: 'managePrivilege', query: {} })
        window.open(routerRes.href, '_blank')
      } else if (
        item.typeId === 5 || // 5 合同到期预警
        item.typeId === 6 || // 6 出租率预警
        item.typeId === 7 || // 7 销售坪效预警
        item.typeId === 8 || // 8 租金收缴率预警
        item.typeId === 9 || // 9 欠费金额预警
        item.typeId === 10 || // 10 欠费账期预警
        item.typeId === 12 // 12 租售比预警
      ) {
        for (let i = 0; i < this.dbUrlList.length; i++) {
          if (this.dbUrlList[i].qtSort === 1004) {
            id = this.dbUrlList[i].id
          }
        }
        window.open(`https://${document.domain}/#/early?id=${id}`, '_blank')
      } else if (item.typeId === 11) { // 11 招商任务预警（高德威斯）
        const routerRes = this.$router.resolve({ path: '/investmentFollowDetail', query: { id: item.taskId } })
        window.open(routerRes.href, '_blank')
      } else if (item.typeId === 14) { // 14 工单
        if (this.isGxjt) {
          const url = item.url.replace(/wbms2|wbms1|wbms/g, 'gxjt')
          window.open(url, '_blank')
        } else {
          window.open(item.url, '_blank')
        }
      } else if (item.typeId === 13) { // 13 审批流程
        const res = this.user.confIds.filter(item => {
          return item.id === 187
        })
        if (res.length === 0) {
          this.$message({
            type: 'warning',
            message: '抱歉，此账号暂无权限访问审批页面！'
          })
          return false
        }
        if (this.isGxjt) {
          const url = item.url.replace(/wbms2|wbms1|wbms/g, 'gxjt')
          window.open(url, '_blank')
        } else {
          window.open(item.url, '_blank')
        }
      } else {
        window.open('/#/notifications', '_blank')
      }
    },
    toCommonListFunc () {
      this.$router.push({
        path: '/commonList'
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.work-index-wrap
  .work-warp
    width 100%
    height 230px
    background url('~@/assets/images/workBench/index-header-bg.png') no-repeat
    background-size 100%
    padding-top 40px
    h2
      font-size 28px
      color #fff
      font-weight bold
      margin-left 64px
    .w-level
      padding 1px 8px
      border 1px solid #DEBA88
      border-radius 12px
      margin-left 64px
      // max-width 240px
      display inline-block
      text-align center
      color #DEBA88
      font-size 14px
      margin-top 12px
      span
        color #DEBA88
.w-content-wrap
  padding 25px 50px
  padding-top 60px
  margin-top -150px
  .w-content-left
    position relative
    width 516px
    height 755px
    background #272930
    box-shadow 0px 1px 12px 0px rgba(0, 0, 0, 0.31)
    border-radius 3px
    padding-top 14px
    margin-bottom 25px
    .w-app-title-name
      font-size 16px
      font-weight 500
      color #FFFFFF
      margin 0px 0 0 16px
    .w-app-list-wrap
      margin-top 12px
      margin-left 9px
      ul li
        cursor pointer
        float left
        width 150px
        height 164px
        text-align center
        margin 0 7px
        border 1px solid transparent
        margin-bottom 14px
        // &.active
        // border 1px solid #FFA134
        // box-shadow 0px 1px 12px 0px rgba(255, 161, 52, 0.31)
        // border-radius 3px
        // &:last-child
        // .w-app-title
        // color rgba(255, 255, 255, 0.2)
        // .w-app-text
        // color rgba(255, 255, 255, 0.1)
        &:hover
          border 1px solid #FFA134
          box-shadow 0px 1px 12px 0px rgba(255, 161, 52, 0.31)
          border-radius 3px
        .w-app-img
          position relative
          img
            // width 42px
            height 42px
            margin-top 38px
          .w-msg-label
            right 20px
            top 2px
        .w-app-title
          font-size 14px
          font-weight 500
          color #FFFFFF
          margin-top 3px
          margin-bottom 3px
        .w-app-text
          font-size 12px
          font-weight 400
          color #A5A5A7
.w-content-right
  width calc(100% - 534px)
  color #fff
  margin-left 16px
  .w-right-top
    padding-left 30px
    background-color #272930
    border-radius 3px
    .w-table-content
      height 219px
      padding-right 30px
      overflow-y auto
      .w-table-item
        border-bottom 1px solid #353539
        color #ffffff
        height 52px
        line-height 52px
        font-weight 400
        .w-table-title
          display inline-block
          font-size 14px
          width 60%
        .w-table-time
          display inline-block
          font-size 14px
          width 20%
        .w-table-btn
          display inline-block
          font-size 14px
          width 20%
          text-align center
          cursor pointer
          color #FFA134
  .w-right-center
    position relative
    margin 16px 0
    height 253px
    background-color #272930
    padding-top 20px
    border-radius 3px
    .w-content-function
      width 63px
      height 16px
      font-size 16px
      font-weight 500
      color #FFFFFF
      margin-left 19px
    .w-manage-btn
      position absolute
      right 24px
      top 10px
      width 24px
      height 11px
      font-size 12px
      font-weight 400
      color #FFA134
      cursor pointer
    .w-used-list
      margin 0px 0 20px 50px
      .w-used-item
        float left
        width 33%
        cursor pointer
        margin 15px 0
        height 68px
        &:hover .w-used-title
          color #FFA134
          span
            color #FFA134
        .w-is-has-used
          color #fff
          .w-used-title
            margin-top 18px
            span
              font-size 20px
              vertical-align middle
              margin-right 8px
          .w-used-tips
            margin-left 28px
            width 159px
            height 12px
            font-size 12px
            font-weight 400
            color #A5A5A7
        .w-is-not-used
          width 210px
          height 68px
          line-height 68px
          border 1px dashed #FFFFFF
          text-align center
          opacity 0.2
          border-radius 3px
          font-size 14px
          font-weight 400
          color #A5A5A7
  .w-right-bottom
    height 196px
    background-color #272930
    padding-top 20px
    border-radius 3px
    position relative
    .w-use-into
      position absolute
      right 24px
      top 10px
      color #FFA134
      font-size 14px
      cursor pointer
    .w-use-help
      width 63px
      height 16px
      font-size 16px
      font-weight 500
      color #FFFFFF
      margin-left 19px
    .use-help-wrap
      display flex
      margin-top 30px
      .help-wrap-left
        width 50%
        display flex
        flex 1
        height 95px
        align-items center
        margin-left 30px
        border-right 1px solid #353539
        .title
          margin-right 30px
          font-size 14px
        .help-item
          width 112px
          height 95px
          background #272930
          border 1px solid rgba(255, 255, 255, 0.1)
          box-shadow 0px 9px 24px 0px rgba(0, 0, 0, 0.32)
          border-radius 3px
          margin 0 12px
          text-align center
          cursor pointer
          color #fff
          &:hover
            border-color #FFA134
          .txt
            font-size 14px
            margin-top 18px
          p>span
            font-size 26px
            margin-top 8px
      .help-wrap-right
        width 50%
        display flex
        flex 1
        margin-left 30px
        height 95px
        align-items center
        .title
          margin-right 30px
          font-size 14px
        .help-item
          width 112px
          height 95px
          background #272930
          border 1px solid rgba(255, 255, 255, 0.1)
          box-shadow 0px 9px 24px 0px rgba(0, 0, 0, 0.32)
          border-radius 3px
          margin 0 12px
          text-align center
          cursor pointer
          color #fff
          &:hover
            border-color #FFA134
          .txt
            font-size 14px
            margin-top 18px
          p>span
            font-size 26px
            margin-top 8px
.w-shadow
  position absolute
  left 0
  bottom 170px
  width 100%
  height 2px
  box-shadow 0px -1px 7px 0px rgba(0, 0, 0, 0.21)
  z-index 11
</style>
